.sosmed-all-container {
  margin-top: 10rem;
  margin-bottom: -17rem;
}
.sosmed-content-prod-title {
  padding-left: 3.6rem;
}
.sosmed-content-prod-title span {
  display: block;
  text-transform: uppercase;
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  color: #53A35B;
  line-height: 3.5rem;
}
.sosmed-content-prod-container {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
/* .portfolio-container {
  padding-right: 3rem;
  margin-bottom: 2rem;
} */
.portfolio-container-right {
  padding-right: 3rem;
  position: relative;
  bottom: 25rem;
}
.portfolio-content {
  display: flex;
  flex-direction: column;
}
.portfolio-content-logo {
  display: flex;
  justify-content: flex-end;
}
.portfolio-content-list {
  display: flex;
  gap: .8rem;
}
.portfolio-content-logo2 {
  margin:  1.4rem 0 1.2rem 0rem;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 820px) {
  .sosmed-all-container {
    margin-top: 2rem;
  }
  .sosmed-content-prod-title {
    padding: 1rem;
    text-align: center;
  }
  .potfolio-content-list {
    width: 7rem;
  }
  .portfolio-content-list img {
    width: 12rem;
  }
  .portfolio-container-right {
    bottom: 0;
    margin-bottom: 20rem;
  }
  .portfolio-content-logo {
    justify-content: center;
  }
  .portfolio-content-logo2 {
    justify-content: center;
  }
  .portfolio-content-logo img {
    width: 6rem;
  }
  .portfolio-content-logo2 img {
    width: 4.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .sosmed-all-container {
    margin-top: 2rem;
  }
  .sosmed-content-prod-title {
    padding: 1rem;
    text-align: center;
  }
  .sosmed-content-prod-title span {
    font-size: 2.5rem;
  }
  .portfolio-content-list img {
    width: 5rem;
  }
  .portfolio-container-right {
    bottom: 0;
    margin-bottom: 20rem;
  }
  .portfolio-content-logo {
    justify-content: center;
  }
  .portfolio-content-logo2 {
    justify-content: center;
  }
  .portfolio-content-logo img {
    width: 5rem;
  }
  .portfolio-content-logo2 img {
    width: 4.2rem;
  }
}

