.social-network-analysis-container {
  min-height: 100vh;
  background-image: url('../../../public/assets/Image/network-analysis-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.social-network-content {
  padding: 2rem 3rem;
}
.social-network-title {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  color: #fff;
  text-transform: uppercase;
}
.social-network-content-image {
  height: 70vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media only screen and (max-width: 820px) {
  .social-network-analysis-container {
    /* min-height: 50vh; */
  }
}

@media only screen and (max-width: 768px) {
  .social-network-analysis-container {
    min-height: 55vh;
    padding-bottom: 2rem;
  }
  .social-network-content {
    text-align: center;
  }
  .social-network-title {
    font-size: 2rem;
  }
  .social-network-content-image {
    height: auto;
    justify-content: center;
    align-items: center;
  }
}