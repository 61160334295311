.digital-container {
  background: rgba(217, 217, 217, 0.4);
  border: 2px solid #ADADAD;
  border-radius: 30px;
  margin: 0 3.125rem 1rem 3.125rem;
  padding-bottom: 2rem;
}
.digital-title-container {
  text-align: center;
  padding: .5rem 0;
}
.digital-content-container {
  display: flex;
}
/* .digital-content-list-container {
  float: left;
  width: 100%;
} */
.digital-content-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
  margin-top: 1rem;
}
.digital-content-website {
  padding: 0 2rem;
  line-height: 1.5rem;
  font-family: 'SF Pro', sans-serif;
  color: #1e1e1e;
  text-decoration: underline;
}
.digital-content-desc {
  text-align: justify;
  padding: 0 2rem;
  line-height: 1.5rem;
  font-family: 'SF Pro', sans-serif;
  color: #1e1e1e;
}
.digital-content-footer {
  padding: 0 2rem;
  line-height: 1.5rem;
  font-family: 'SF Pro', sans-serif;
  color: #1e1e1e;
}

@media only screen and (max-width: 830px) {
  .digital-container {
    margin: .8rem;
  }
  .digital-content-container {
    flex-direction: column;
  }
  .digital-content-logo img {
    width: 8rem;
  }
}

