.offair-title-container {
  text-align: center;
}
.offair-container {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .offair-container {
    flex-direction: column;
  }
  .offair-container img {
    margin: .5rem;
  }
}

@media only screen and (max-width: 820px) {
  .offair-container img {
    height: 10rem;
  }
  .offair-container {
    margin-bottom: 2rem;
  }
}