.footer {
  position: fixed;
  width: 100%;
  height: 3.75rem;
  bottom: 0;
  background-color: #0F071F;
  z-index: 2;
}
.footer-content {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  font-family: 'SF Pro Rounded', sans-serif;
}
.footer-title {
  color: #fff;
}
.footer-content span {
  margin: 1.5rem 3rem;
  font-weight: 100;
  font-size: small;
}
.sosmed-content img {
  margin: 1rem;
}

@media only screen and (max-width: 768px) {
  .footer {
    height: 4.5rem;
    width: 100vw;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .footer-content span {
    margin: .5rem 0;
  }
  .sosmed-content img {
    margin: .5rem;
  }
}