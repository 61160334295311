.report-sample-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.report-sample-content {
  border: 1px solid rgb(221, 221, 221);
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  margin-top: 1.8rem;
}
.report-sample-content span {
  display: block;
  text-align: center;
  font-family: 'Visby Round', sans-serif;
  font-size: 1.25rem;
}
.report-sample-title {
  /* display: flex;
  justify-content: center; */
}
.zoom {
  transition: transform .2s;
}
.portfolio-item .zoom:hover {
  transform: scale(1.1);
} 

@media only screen and (max-width: 820px) {
  .report-sample-title span{
    font-size: .7rem;
  }
  .report-sample-content {
    margin: .2rem;
  }
}

@media only screen and (max-width: 768px) {
  .report-sample-content-container {
    display: flex;
    flex-direction: column;
  }
  .report-sample-title {
    text-align: center;
    font-size: 2rem;
  }
  .report-sample-content {
    padding: .5rem;
  }
  .report-sample-title span{
    font-size: .8rem;
  }
  .portfolio-item {
    text-align: center;
  }
  .report-sample-title2 span {
    text-align: center;
    font-size: 2.3rem;
  }
}