.layanan-container {
  background-color: #009DAE;
  max-height: 123vh;
  padding: 3rem 3.5rem 2rem 2rem;
  margin-bottom: 1.5rem;
}
.layanan-title {
  font-family: 'Visby', sans-serif;
  font-size: 2rem;
  color: #f1f1f1;
  margin-bottom: 1.5rem;
}
.layanan-content-container {
  display: flex;
  justify-content: space-between;
}
.layanan-content-web-container {
  margin-top: 6rem;
}
.layanan-content-header {
  font-family: 'SF Pro Rounded', sans-serif;
  color: #f1f1f1;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
}
.layanan-content-desc {
  font-family: 'SF Pro Rounded Thin', sans-serif;
  color: #f1f1f1;
  font-size: 1.25rem;
  letter-spacing: .5px;
}
.layanan-bold {
  font-family: 'SF Pro Rounded', sans-serif;
  color: #f1f1f1;
  font-size: 1.25rem;
}
.layanan-content-detail {
  text-align: justify;
  max-width: 27.75rem;
}
.layanan-content-detail2 {
  max-width: 33.3125rem;
}
.layanan-content-detail3 {
  max-width: 63.75rem;
}
.layanan-content-image {
  position: relative;
  bottom: 61.5rem;
  left: 16rem;
}
.layanan-content-container-last {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

@media only screen and (max-width: 820px) {
  .layanan-container {
    height: 58vh;
  }
  .layanan-title {
    text-align: center;
  }
  .layanan-content {
    margin-top: .5rem;
  }
  .layanan-content-web-dev {
    margin-top: .5rem;
  }
  .layanan-content-container {
    flex-direction: column;
  }
  .layanan-content-web-container {
    margin: 0;
  }
  .layanan-content-container-last {
    margin: 0;
  }
  .layanan-content-header {
    font-size: .8rem;
  }
  .layanan-content-desc {
    font-size: .8rem;
  }
  .layanan-bold {
    font-size: .8rem;
  }
  .layanan-content-image {
    bottom: 37rem;
    left: 37.3rem;
  }
}

@media only screen and (max-width: 768px) {
  .layanan-container {
    height: 87vh;
  }
  .layanan-title {
    text-align: center;
  }
  .layanan-content {
    margin-top: .5rem;
  }
  .layanan-content-web-dev {
    margin-top: .5rem;
  }
  .layanan-content-container {
    flex-direction: column;
  }
  .layanan-content-web-container {
    margin: 0;
  }
  .layanan-content-container-last {
    margin: 0;
  }
  .layanan-content-header {
    font-size: .8rem;
  }
  .layanan-content-desc {
    font-size: .8rem;
  }
  .layanan-bold {
    font-size: .8rem;
  }
  .layanan-content-image {
    display: none;
  }
}
