.ekosistem-container {
  padding: 5rem 5rem 0 5rem;
}
.ekosistem-title {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
}
.ekosistem-image-content {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 3rem 0;
}
.ekosistem-image-mobile img {
  display: none;
}
.ekosistem-image-list {
  display: flex;
  justify-content: center;
}
.ekosistem-desc-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.ekosistem-desc-content {
  width: 50vw;
}
.ekosistem-desc {
  padding: 1rem;
}
ul.ekosistem-list {
  margin: -.1px;
  margin-bottom: 1.5rem;
}
.ekosistem-desc span {
  font-family: 'SF Pro Light', sans-serif;
}
.ekosistem-list li {
  padding: .2rem;
}
.ekosistem-list-content {
  margin-top: 2rem;
}

@media only screen and (max-width: 768px) {
  .ekosistem-container {
    padding: 1rem;
  }
  .ekosistem-image-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ekosistem-image-desktop {
    display: none;
  }
  .ekosistem-image-mobile img {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 820px) {
  .ekosistem-image-content img {
    height: 12rem;
  }
}