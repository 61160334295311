.pendahuluan-container {
  margin-bottom: 20rem;
}
.pendahuluan-container-top {
  background-color: #1F082D;
  padding-top: 3rem;
  height: 100vh;
}
.pendahuluan-container-bot {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 2;
}
.pendahuluan-shape {
  width: 453px;
  height: 798px;
  background-color: #FF8FB1;
  margin-top: 1rem;
}
.pendahuluan-bagan {
  position: absolute;
  left: 27;
}
.pendahuluan-bagan {
  position: absolute;
  left: 27;
}
.pendahuluan-content {
  display: flex;
}
.pendahuluan-text-container {
  display: flex;
  flex-direction: column;
  margin: 11rem 0 0 5rem;
}
.pendahuluan-title {
  color: #F1F1F1;
  display: block;
  font-size: 2rem;
  font-family: 'Visby', sans-serif;
  padding-bottom: 1rem;
}
.pendahuluan-desc {
  color: #F1F1F1;
  text-align: justify;
  letter-spacing: 3.5px;
  line-height: 1.8rem;
  font-family: 'SF Pro Rounded', sans-serif;
}
.pendahuluan-image-container {
  margin-top: 4.4rem;
}
.pendahuluan-text2 {
  position: relative;
  top: 20rem;
  left: 57rem;
}
.pendahuluan-title2 {
  display: block;
  font-family: 'Visby', sans-serif;
  font-size: 2rem;
}
.pendahuluan-text2 span  {
  color: #1E1E1E;
  letter-spacing: 2.5px;
  line-height: 1.8rem;
  font-family: 'SF Pro', sans-serif;
}
.pendahuluan-image-pink {
  position: relative;
  left: 50.5rem;
  top: 37rem;
}

@media only screen and (max-width: 768px) {
  .pendahuluan-content {
    flex-direction: column-reverse;
  }
  .pendahuluan-image-container {
    margin: 0;
    text-align: center;
  }
  .pendahuluan-shape {
    width: 400;
    height: 347;
    background-color: #FF8FB1;
    margin-top: 1rem;
  }
  .pendahuluan-bagan {
    margin: 0;
  }
  .pendahuluan-image-pink {
    display: none;
  }
  .pendahuluan-container-top {
    height: auto;
  }
  .pendahuluan-desc {
    text-align: left;
  }
  .pendahuluan-text-container {
    margin: 1rem 2rem;
  }
  .pendahuluan-container-bot {
    height: 30vh;
  }
  .pendahuluan-text2 {
    left: 1rem;
    top: 25rem;
  }
}

@media only screen and (max-width: 820px) {
    .pendahuluan-content {
    flex-direction: column-reverse;
  }
  .pendahuluan-image-container {
    margin: 0;
    text-align: center;
  }
  .pendahuluan-shape {
    width: 400;
    height: 347;
    background-color: #FF8FB1;
    margin-top: 1rem;
  }
  .pendahuluan-bagan {
    margin: 0;
  }
  .pendahuluan-image-pink {
    display: none;
  }
  .pendahuluan-container-top {
    height: auto;
  }
  .pendahuluan-desc {
    text-align: left;
  }
  .pendahuluan-text-container {
    margin: 1rem 2rem;
  }
  .pendahuluan-container-bot {
    height: 30vh;
    flex-direction: row;
  }
  .pendahuluan-text2 {
    left: 1rem;
    top: 25rem;
  }
}