body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f1f3f4;
}

@font-face {
  font-family: 'Visby Round';
  src: URL('/public/assets/Fonts/VisbyRoundCF-Bold.woff') format('truetype');
}

@font-face {
  font-family: 'Visby Heavy Bold';
  src: url('/public/assets/Fonts/VisbyCF-HeavyOblique.otf');
}
@font-face {
  font-family: 'Visby';
  src: url('/public/assets//Fonts/VisbyCF-Bold.otf');
}

@font-face {
  font-family: 'SF Pro';
  src: url('/public/assets//Fonts/SF-Pro.ttf');
}

@font-face {
  font-family: 'SF Pro Semi Bold';
  src: url('/public/assets/Fonts/SF-Pro-Display-Semibold.otf');
}

@font-face {
  font-family: 'SF Pro Light';
  src: url('/public/assets/Fonts/SF-Pro-Text-Light.otf');
}

@font-face {
  font-family: 'SF Pro Rounded';
  src: url('/public/assets/Fonts/SF-Pro-Rounded-Regular.otf');
}

@font-face {
  font-family: 'SF Pro Rounded Thin';
  src: url('/public/assets/Fonts/SF-Pro-Rounded-Thin.otf');
}