.reach-container {
  margin: 6rem 3.5rem 8.125rem 3.5rem;
}
.reach-title {
  font-family: 'Visby', sans-serif;
  text-transform: uppercase;
  color: #D62728;
  text-align: center;
  font-size: 6.875rem;
}
.reach-subtitle {
  font-family: 'Visby', sans-serif;
  text-transform: uppercase;
  color: #D62728;
  text-align: center;
  font-size: 3.125rem;
}

@media only screen and (max-width: 820px) {
  .reach-title {
    font-size: 3rem;
  }
  .reach-subtitle {
    font-size: 1.7rem;
  }
}