.tv-program-title-container {
  text-align: center;
}
.tv-program-container {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .tv-program-container {
    flex-direction: column;
  }
  .tv-program-container img {
    margin: .5rem 0;
  }
}

@media only screen and (max-width: 820px) {
  .tv-program-container img {
    height: 10rem;
  }
}