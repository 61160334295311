.kontak-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kontak-title {
  font-family: 'Visby', sans-serif;
  font-size: 2rem;
}
.kontak-subtitle {
  font-family: 'SF Pro Light', sans-serif;
  text-align: center;
}
.kontak-subtitle span {
  display: block;
}
.kontak-image {
  position: relative;
  right: 9rem;
}