.web-dev-container {
  background-image: url(../../../public/assets/Image/portfolio-bg.png);
  padding: 1rem 0 1rem 0;
}
.web-dev-title {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  color: #D62728;
  text-transform: uppercase;
  text-align: center;
}
.web-dev-content {
  display: flex;
  justify-content: flex-end;
}
.web-dev-content img {
  padding: 1rem;
}

@media only screen and (max-width: 768px) {
  .web-dev-container {
    background: none;
  }
  .web-dev-title {
    font-size: 3rem;
  }
  .web-dev-content {
    flex-direction: column;
    width: 100vw;
    text-align: center;
  }
  .web-dev-content img {
    height: 13rem;
  }
}

@media only screen and (max-width: 820px) {
  .web-dev-container {
    background: none;
  }
  .web-dev-content {
    justify-content: center;
  }
  .web-dev-content img {
    height: 15rem;
    justify-content: center;
  }
}