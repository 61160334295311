.hero {
  margin-bottom: -5px;
}
.hero img {
  width: 100%;
  height: 100%;
}
/* @media only screen and (max-width: 768px) {
  #hero {
    background-image: url("../../../public/assets/Mobile/hero.png");
  }
} */