.sosmed-assets-container {
  min-height: 100vh;
}
.sosmed-monitor-title {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.sosmed-monitor-title2 {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  margin: 2rem 0 1rem 0;
  text-transform: uppercase;
}
.sosmed-monitoring-container {
  background-color: #ADADAD;
  padding: 2rem 0 0 3rem;
  background-image: url(../../../public/assets/Image/sosmed-bg.png);
  background-position: right 0 top 0;
  background-repeat: no-repeat;
}
.real-time-monitoring-image {
  padding-bottom: 4.3rem;
}
.graphic-monitoring {
  margin-bottom: 5rem;
}
.graphic-monitoring img {
  padding: .5rem;
}

@media only screen and (max-width: 820px) {
  .sosmed-monitoring-container {
    text-align: center;
    padding: 1rem;
  }
  .graphic-monitoring img:last-child {
    height: 16rem;
  }
  .real-time-monitoring-image img {
    height: 20rem;
  }
}

@media only screen and (max-width: 768px) {
  .sosmed-monitor-title {
    font-size: 2rem;
  }
  .sosmed-monitoring-container {
    padding: 0;
    text-align: center;
  }
  .sosmed-monitor-title2 {
    font-size: 2rem;
  }
  .graphic-monitoring img:last-child {
    height: 10rem;
  }
  .real-time-monitoring-image img {
    height: 10rem;
  }
}
