.kenapa-container {
  background-color: #F1F1F1;
  display: flex;
  justify-content: space-between;
}
.kenapa-title-container {
  padding: 0rem 0 0 5rem;
  margin-top: 5rem;
  max-width: 100vw;
}
.kenapa-title {
  font-family: 'Visby', sans-serif;
  font-size: 2rem;
  display: block;
}
.kenapa-desc {
  padding-top: 2rem;
}
ul.kenapa-list {
  margin: -.1px;
  margin-bottom: 1.8rem;
  margin-top: 5px;
}
.kenapa-desc span {
  font-family: 'SF Pro Light', sans-serif;
}
.kenapa-image {
  margin: -4px;
  position: relative;
  left: -.2rem;
}
#pbold {
  font-family: 'SF Pro Semi Bold', sans-serif;
}

@media only screen and (max-width: 820px) {
  .kenapa-container {
    flex-direction: column-reverse;
    margin-top: -25rem;
  }
  .kenapa-title-container {
    padding: 1rem;
    margin: 0;
  }
  .kenapa-image {
    text-align: right;
  }
  .kenapa-image img {
    height: 20rem;
    position: relative;
    top: 35.6rem;
  }
}

@media only screen and (max-width: 768px) {
  .kenapa-container {
    flex-direction: column-reverse;
  }
  .kenapa-title-container {
    padding: 1rem;
    margin: 0;
  }
  .kenapa-title {
    font-size: 1.5rem;
  }
  .kenapa-image {
    text-align: right;
  }
  .kenapa-image img {
    left: 12rem;
  }
}
