.klien-container {
  margin-bottom: 4.5rem;
}
.klien-header {
  margin: 0 0 6rem 3rem;
}
.klien-header span {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  color: #009DAE;
}
.klien-ijo {
  background-color: #FF8FB1;
  width: 50px;
  height: 836px;
  border-radius: 0 30px 30px 0;
}
.klien-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 820px) {
  .klien-bundle {
    padding: 1rem;
  }
  .klien-bundle img {
    height: 45rem;
  } 
}

@media only screen and (max-width: 768px) {
  .klien-container {
    margin: 0;
  }
  .klien-header {
    margin: 0;
  }
  .klien-content-container {
    margin: 1rem;
    flex-direction: row;
    justify-content: space-between;
  }
  .klien-ijo {
    display: none;
  }
  .klien-bundle img {
    height: 23rem;
  }
  .klien-people {
    /* display: none; */
  }
}