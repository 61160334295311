html {
  scroll-behavior: smooth;
}

html, body {
  overflow-x: hidden;
}
/* body {
  position: relative
} */

.btn:link,
.btn:visited {
  border-radius: .7rem;
  transition: all .2s;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.container {
  margin: 2rem 3.125rem;
}
.default-title {
  font-family: 'Visby', sans-serif;
  font-size: 4rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .default-title {
    font-family: 'Visby', sans-serif;
    font-size: 3rem;
    text-transform: uppercase;
  }
}