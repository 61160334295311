.different-angle-container {
  display: flex;
}
.different-angle-title {
  background-color: #53A35B;
  height: 527px;
  width: 572px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.different-angle-title span {
  display: block;
  font-family: 'Visby', sans-serif;
  font-size: 5rem;
  color: white;
}
.different-angle-image {
  background-color: #fff;
  width: 100vw;
  text-align: center;
}

@media only screen and (max-width: 820px) {
  .different-angle-container {
    flex-direction: column-reverse;
  }
  .different-angle-title {
    background-color: #53A35B;
    height: 111px;
    width: 391px;
    width: 100%;
    text-align: center;
  }
  .different-angle-title span {
    display: inline;
    font-family: 'Visby', sans-serif;
    font-size: 2.5rem;
    color: white;
  }
  .different-angle-title-content {
    text-align: center;
  }
}