.navbar {
  width: 100%;
  background-color: #0F0D10;
  position: fixed;
  top: 0;
  height: 3rem;
  z-index: 3;
  display: flex;
  justify-content: space-between;
}
.navitem li {
  list-style: none;
  color: white;
}
.title {
  font-family: 'SF Pro Rounded', sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
  letter-spacing: .5px;
  color: #fff;
}
.navbar {
  opacity: 0.75;
}
.navbar-container input[type="checkbox"],
.navbar-container .hamburger-lines {
  display: block;
}

.navbar-container {
  display: block;
  position: relative;
  height: 64px;
}

.navbar-container input[type="checkbox"] {
  position: fixed;
  display: block;
  height: 32px;
  width: 30px;
  top: 20px;
  left: 20px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.navbar-container .hamburger-lines {
  display: block;
  height: 23px;
  width: 35px;
  position: fixed;
  top: 17px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.navbar-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
}

.navbar-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.navbar-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.navbar-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
  padding-top: 4rem;
  background: #333;
  height: 100vh;
  max-width: 400px;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -120px;
  margin-top: 1rem;
  padding-left: 5.5rem;
  padding-right: 1rem;
  transition: transform 0.5s ease-in-out;
  list-style: none;
}

.navbar .menu-items li {
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  font-weight: 500;
}

/* .logo {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 2rem;
} */

.navbar-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0vh);
}

.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(35deg);
}

.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-35deg);
}


.logo {
  margin: .5rem 3rem;
  display: flex;
  align-items: center;
}
.nav-item{
  display: flex;
  align-items: center;
}
.nav-item ul {
  display: flex;
  justify-content: space-around;
  margin-right: 1rem;
}
.nav-item ul li {
  padding: 1rem;
  font-size: small;
}

@media only screen and (max-width: 768px) {
  .navbar .menu-items {
    padding-top: 1rem;
  }
  .navbar .menu-items li {
    font-size: 1rem;
    margin-bottom: .8rem;
  }
}